<template>
  <div class="row detail-img">
    <div class="col-12 mb-18">
        <div class="w-100 main-frame-img">
          <img :src="dynamicImg" class="main-img" alt="main">
        </div>
    </div>
    <div class="col-4 pr-9"  id="selectoption">
      <div class="text-center">
        <div class="w-100 cursor" >
          <img :src="thumbnail" class="thumbnail" alt="thumbnail"  @click="handleDynamicImg(thumbnail)"/>
        </div>
      </div>
    </div>
    <div class="col-4 pl-9 pr-9">
      <div class="text-center" v-if="image_1">
        <div class="w-100 cursor">
          <img :src="image_1" class="thumbnail" alt="image_one"  @click="handleDynamicImg(image_1)"/>
        </div>
      </div>
    </div>
    <div class="col-4 pl-9">
      <div class="text-center" v-if="image_2">
        <div class="w-100 cursor">
          <img :src="image_2" class="thumbnail" alt="image_two" @click="handleDynamicImg(image_2)"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    thumbnail: String,
    image_1: String,
    image_2: String,
  },
  data(){
    return {
      dynamicImg: this.thumbnail
    }
  },
  methods:{
    handleDynamicImg(img){
      this.dynamicImg = img
    }
  },
  watch:{
    thumbnail(url){
      this.dynamicImg = url;
    }
  }
};
</script>

<style scoped>
.cursor{
  cursor: pointer;
}
img{
  width: inherit;
  height: inherit;
}
.custom-height{
  height: inherit;
}
</style>