<template>
    <div class="main" v-if="getLessonDetail">
        <Breadcrumb :breadcrumb="getLessonDetail.breadcrumb"/>
        <div class="detail-lesson">
            <div class="row" v-if="getLessonDetail && getLessonDetail.lesson_not_available">
                <div class="col-12 text-center p-5">
                    <h3>{{$t('lesson.no_available')}}</h3>
                </div>
            </div>
            <template v-else>
                <section-detail :data="getLessonDetail"/>
                <div class="mt-0 mb-5" v-if="relatedLessons.length">
                    <RelatedLesson :items="relatedLessons" />
                </div>
            </template>
            <RegisterModal
                :isVisible="getPopUpState"
                @cancel="actionCancel"
            />
        </div>
        <MetaTags 
            :title="`${getLessonDetail.title}｜ResMom相談online`"
            :description="getLessonDetail.introduction"
            :ogImage="getLessonDetail.preview ? getLessonDetail.preview.thumbnail : ''"
        />
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import sectionDetail from "./section-detail.vue";
import RelatedLesson from "@modules/pc/page/lesson-detail/components/lesson/related-lesson.vue";

export default {
    data() {
        return {
            relatedLessons: [],
        };
    },
    components: {
        RelatedLesson,
        sectionDetail
    },
    async created() {
        await this.loadDetail();
    },
    computed: {
        ...mapGetters({
            getLessonDetail: "lessonDetail/getLessonDetail",
            getPopUpState: "lessonDetail/getPopUpState",
        }),
    },
    methods: {
        ...mapActions({
            actionFetchLessonDetail: "lessonDetail/fetchLessonDetail",
            actionSetPopup: "lessonDetail/setPopup",
        }),
        actionCancel() {
            //tirgger register mms now in this component
            this.actionSetPopup();
        },
        async loadDetail() {
            let lessonId = this.$router.currentRoute.params.lessonId;
            const memberId = this.getMemberId();
            this.$isLoading(true);
            await this.actionFetchLessonDetail({ lessonId: lessonId, member_id: memberId });
            this.relatedLessons = this.getLessonDetail.related_lesson;
            this.$isLoading(false);
        }
    },
    watch: {
        '$route.params.lessonId': function(id) {
            if(id !== this.lessonId){
                this.loadDetail();
            }
        }
    }
};
</script>

<style>
</style>