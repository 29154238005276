<template>
  <div class="container item-price-side">
    <div class="row">
      <div class="col-12 mt-3 purchase">
            <h4 class="price">{{ formatCurrency(price.currency, price.total) }} {{ $t("lesson.included_tax") }}</h4>
            <div class="item">
              <a href="#selectoption" >{{ $t("lesson.item.select_option") }} </a>
            </div>
            <button
                class="text-center button-purchase"
                @click="handleClickPurchase"
                v-if="!_disablePurchased"
                :disabled="disabledBtn"
              >
              {{ $t("lesson.detail.reservation_screen") }}
            </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      disabledBtn: false
    }
  },
  props: {
    data: Object,
    price: Object,
  },
  computed: {
    teacherInfo() {
      return this.data.teacher_info;
    },
    _disablePurchased() {
      const memberId = this.getMemberId();
      return memberId == this.teacherInfo.member_id;
    },
  },
  methods: {
    handleClickPurchase() {
      if(this.disabledBtn)return;
      this.disabledBtn = true;
      this.$parent.handleClickPurchase();
      setTimeout(() => {
        this.disabledBtn = false;
      }, 10000);
    }
  },
}
</script>

<style></style>